import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BauturaPage = () => (
  <Layout>
    <SEO title="Băutura la nuntă și Cocktail bar" />
    <div className="drawer article-content">
      <h1 className="article-title">Băutura la nuntă și Cocktail bar</h1>

      <section>
        <p>
          Cu siguranță muzica, băutura și mâncarea sunt elementele cărora le
          este acordată cea mai mare atenție de către invitați.
        </p>
        <p>
          Cum altfel să te distrezi dacă nu cu muzică bună și cum să dansezi
          dacă nu ai ce alege din meniul de băuturi?
        </p>
      </section>

      <section>
        <p>
          Cele mai multe restaurante au inclusă în ofertă și meniul de băuturi,
          însă cel mai probabil vei avea de ales între mai multe opțiuni, iar pe
          lângă variantele clasice, ar fi minunat și un cocktail bar.
        </p>
        <p>
          Dacă resturantul ales nu vă oferă și meniu de băuturi sau dacă alegi
          să te ocupi tu de achiționarea acestora trebuie să te sfătuiești cu
          cei de la restaurant în legătură cu cantitățile necesare pentru
          fiecare categorie de băutură.
        </p>
        <p>
          Dacă te vei ocupa personal de achiziționarea băuturii asigură-te că
          vei primi factură, cei de la restaurant au nevoie de toate documentele
          achiziției și că există posibilitatea de retur, dacă sticlele/baxurile
          nu au fost desfăcute, mai bine să achiziționezi mai multă băutură
          decât să fie nevoie să faci cumpărături în noaptea nunții.
        </p>
        <p>
          Cantitățile de alcool depind de anotimp, de vârsta celor invitați,
          uneori de zonă sau de locația evenimentului (într-unele zone se bea
          țuică multă la nunți, la altele se bea multă bere) și de varietatea
          meniului.
        </p>

        <img src="https://images.unsplash.com/photo-1560963689-7f69d8ca0518?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=693&q=80" />

        <p>
          De obicei cantitatea de apă recomandată este de 500ml - 1l plată și
          aceeași cantitate minerală pentru fiecare invitat, 500 ml suc natural
          și 500 ml cola/pepsi, 200 ml tărie (aici poți calcula 200 ml doar
          pentru bărbați, dacă vei avea și o băutură de tipul lichior), 100 sau
          200 ml șampanie (la întâmpinarea invitaților, dacă vei servi și la
          tort va trebui să iei o cantitate mai mare), vin - de obicei cam o
          sticlă de persoană (acum depinde foarte mult și câte tipuri de vin vei
          lua, cel mai băut este cel alb, urmat de roze și apoi de cel roșu, iar
          dacă vei lua un singur tip din fiecare, de obicei se cumpără demisec)
          și poate și bere.
        </p>
      </section>

      <h3 className="paragh-title">Avantajele unui cocktail bar:</h3>
      <section>
        <ul>
          <li>
            cocktailuri făcute ca la carte. Dacă resturantul îți propune și
            varianta de cocktail-uri incluse în meniu cere detalii suplimentare,
            de obicei nu au instrumente potrivite și efectiv amestecă niște
            ingrediente într-un pahar
          </li>
          <li>
            nu va trebui să te ocupi de achiziția altor tipuri de băuturi pentru
            că ele fiind baza pentru cocktailuri, invitații le pot comanda de la
            bar. Sunt puține șanse să își dorească cineva să bea vodkă seacă,
            însă dacă vrea își va putea lua de la bar și tu nu va trebui să
            cumperi și vodka
          </li>
          <li>oamenii își pot personaliza băuturile, nu au meniu impus</li>
          <li>
            vei economisi cu restul de băutură dacă oamenii vor avea opțiunea
            cocktail-urilor
          </li>
          <li>
            vei putea oferi mai multe tipuri de cocktailuri, cu alcool sau fără
            și shot-uri
          </li>
          <li>
            barmanii se pricep extrem de bine să întrețină atmosfera și oamenii
            vor prefera să meargă la bar și să-ți comande singuri băutura.
          </li>
        </ul>
      </section>

      <p>
        În funcție de ce oferă localul, te poți adapta, astfel încât invitații
        să aibă mai multe opțiuni, să nu fie o bătaie de cap prea mare
        achiziționarea lor și nici o risipă de bani (în cazul în care băutura nu
        poate fi returnată).
      </p>
      <p>
        După ce soliciți și analizezi ofertele de cocktail bar este important să
        pui cât mai multe întrebări legate de cantitatea de băutura necesară (de
        obicei, îți pot prezenta un calcul orientativ în funcție de numărul de
        persoane și cocktailurile alese), dacă poți alege tu băuturile sau au ei
        deja un meniu, dacă te pot ajuta și cu achiționarea altor băuturi, în
        afară de cele pentru cocktail.
      </p>
      <p>Cu siguranță un cocktail bar vă va asigura o petrecere de neuitat.</p>

      <div className="nav-section">
        <Link to="/cununiaReligioasa">◀ Cununia religioasa </Link>
        <Link to="/candyBar">▶ Candy Bar</Link>
      </div>
    </div>
  </Layout>
)

export default BauturaPage
